import { useEffect, useRef, useState } from "preact/hooks";
import DiagramView from "../../components/DiagramViewer";
import { DiagramDto, GetPartPriceData, GetPartPriceEvent, ModelDto, PartPrice, ViewProductClickEvent } from "../../dataLoaders/dto";
import useSWR, { SWRResponse } from "swr";
import { classNames } from "../../utils/general";
import { h } from "preact";
import { RouterProps } from "preact-router";

const SiteSettings = {
  RepairIntelligenceApiBaseUrl: process.env.RepairIntelligenceApiBaseUrl,
};

const getDiagramsByModelId = async (modelId: number): Promise<DiagramDto[] | undefined> => {
  const response = await fetch(`${SiteSettings.RepairIntelligenceApiBaseUrl}/models/${modelId}/diagrams?api-version=v1`);
  if (!response.ok) return undefined;
  const data = (await response.json()) as { diagrams: DiagramDto[] };
  return data.diagrams;
};

export const useDiagramByModelIdLoader = (modelId: number | null): SWRResponse<DiagramDto[] | undefined> => {
  const key = modelId === null ? null : `/models/${modelId}/diagrams`;
  const swrResponse = useSWR<DiagramDto[] | undefined, Error>(key, () => getDiagramsByModelId(modelId ?? -1));
  return swrResponse;
};

const getModelByModelId = async (modelId: number): Promise<ModelDto | undefined> => {
  const response = await fetch(`${SiteSettings.RepairIntelligenceApiBaseUrl}/model/${modelId}?api-version=v1`);
  if (!response.ok) return undefined;
  const data = (await response.json()) as ModelDto ;
  return data;
};

export const useModelByModelIdLoader = (modelId: number | null): SWRResponse<ModelDto | undefined> => {
  const key = modelId === null ? null : `/model/${modelId}`;
  const swrResponse = useSWR<ModelDto | undefined, Error>(key, () => getModelByModelId(modelId ?? -1));
  return swrResponse;
};

interface DiagramProps extends RouterProps {
  modelId? : number;
}

const Diagrams = ({modelId} : DiagramProps) => {
  const [partPrices, setPartPrices] = useState<PartPrice [] | null>(null);
  const { data: diagrams, isLoading: diagramsLoading } = useDiagramByModelIdLoader(modelId);
  const { data: model } = useModelByModelIdLoader(modelId);
  const [diagramId, setDiagramId] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if(event.type !== 'message')
        return;

      if(!(event.data && event.data.eventType))
        return;

      if(event.data.eventType === 'partPriceResponse')  {
        var partPrices = event.data.data as PartPrice[];
        setPartPrices(partPrices);
      }
    });
    
  }, []);

  if (!diagramsLoading && (!diagrams || diagrams.length === 0)) {
    return <div className='py-4'>No diagrams are currently available for this model.</div>;
  }

  const onDiagramClick = (diagram: DiagramDto) => {
    setPartPrices(null);
    setDiagramId(diagram.diagram_id);
    setTimeout(() =>  containerRef.current?.scrollIntoView(), 100);
  };

  const handleGetPartPrices = (partsData : GetPartPriceData[]) => {
    const getPartPriceEvent : GetPartPriceEvent = {
      eventType: 'getPartPrice',
      "data" : partsData
    };
    window.parent.postMessage(getPartPriceEvent, '*')
  };

  const handleViewProductClick = (oemPartId: string, bapgPartNumber: string) => {
    const viewProductClickEvent : ViewProductClickEvent = {
      eventType: 'viewProductClicked',
      data: {
        oemPartId: oemPartId,
        bapgPartNumber : bapgPartNumber
      }
    };
    window.parent.postMessage(viewProductClickEvent, '*')
  };

  return (
    <div className="flex bd-highlight" style={{ color: 'black' }}>
    <div className="w-full">
      <div className="py-2">
        <b>Model Number : {model?.base_name}</b>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-6 pb-4">
        {diagrams?.map((diagram) => (
          <button
            key={diagram.diagram_id}
            type="button"
            onClick={(event) => {
              event.preventDefault();
              onDiagramClick(diagram);
            }}
            className="flex flex-col justify-between"
            title={diagram.name}
          >
            <div className="text-sm mb-2 font-bold line-clamp-2">{diagram.name}</div>
            <div className="relative p-4  rounded-2xl border-gray-200 border h-48 flex ">
              <img src={diagram.thumbnail_url} alt={diagram.name} className="object-cover" />
              <div className={classNames('absolute top-0 left-0 bg-black w-full h-full rounded-2xl', diagramId === diagram.diagram_id ? 'opacity-20' : 'opacity-0')} />
            </div>
          </button>
        ))}
      </div>
      <div ref={containerRef} className="py-5">
        {diagramId !== null ? <DiagramView diagramId={diagramId} partPrices={partPrices} onGetPartPrices={handleGetPartPrices} onViewProductClick={handleViewProductClick}/> : null}
      </div>
    </div>
  </div>
  )
};

export default Diagrams;
